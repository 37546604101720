import badminton from "./01_badminton.png";
import venteFromageVin from "./02_venteFromageVin.png";
import programme from "./05_programme.png";
import pickleball from "./06_pickleball.png";
import ecoleTennis from "./07_ecoleTennis.png";
import soireeClub from "./08_soireeClub.png";
import octobreRose from "../../img/img-octobrerose/02_imgOctobreRose-2024-1068x632.jpeg";

const imgAnim = [
  {
    id: 1,
    img: programme,
    title: "Programme 2024",
    subTitle: "Découvrez toutes les animations prévues cette saison ...",
    path: "/animations/programme",
  },
  {
    id: 2,
    img: venteFromageVin,
    title: "Vente de Fromages et de Vins",
    subTitle: "Soutenez le club en passant une commande ...",
    path: "/animations/ventes",
  },
  {
    id: 3,
    img: ecoleTennis,
    title: "Fête de l'école de tennis",
    subTitle:
      "En juin participez à la fête des enfants inscrits à l'école de tennis ...",
    path: "/animations/feteecole",
  },
  {
    id: 4,
    img: soireeClub,
    title: "Soirée Club",
    subTitle: "Soirée d'été entre amis ...",
    path: "/animations/soireeclub",
  },
  {
    id: 5,
    img: badminton,
    title: "Badminton",
    subTitle: "Tournois de la Saint Patrick",
    path: "/animations/badminton",
  },
  {
    id: 6,
    img: pickleball,
    title: "Pickleball",
    subTitle: "Tournoi amical de novembre",
    path: "/animations/pickleball",
  },
  {
    id: 7,
    img: octobreRose,
    title: "Octobre Rose",
    subTitle: "Journée Octobre Rose du 19 octobre 2024",
    path: "/animations/octobrerose",
  },
];

export default imgAnim;
