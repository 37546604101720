import logoBabolat from "../../img/img-partners/logoBabolat.jpg";
import logoBourdonCharpente from "../../img/img-partners/logoBourdonCharpente.jpg";
import logoBourdonConstruction from "../../img/img-partners/logoBourdonConstruction.jpg";
import logoBourdonMenuiserie from "../../img/img-partners/logoBourdonMenuiserie.jpg";
import logoIDConseils from "../../img/img-partners/logoIDConseils.jpg";
import logoIntermarche from "../../img/img-partners/logoIntermarche.png";
import logoJaninSports from "../../img/img-partners/logoJaninSports.jpg";
import logoBernet from "../../img/img-partners/logoBernetFaience1.png";
import logoG2F from "../../img/img-partners/logoG2F.jpg";

const imgPartners = [
  {
    id: 1,
    img: logoBabolat,
    link: "https://www.babolat.com/fr",
  },
  {
    id: 2,

    img: logoBourdonCharpente,
    link: "https://www.bourdon01.com/",
  },
  {
    id: 3,
    img: logoBourdonConstruction,
    link: "https://www.bourdon01.com/",
  },
  {
    id: 4,
    img: logoBourdonMenuiserie,
    link: "https://www.bourdon01.com/",
  },
  {
    id: 5,
    img: logoIDConseils,
    link: "https://www.idconseils.fr/",
  },
  {
    id: 6,
    img: logoIntermarche,
    link: "https://www.intermarche.com/magasin?utm_source=gmb",
  },
  {
    id: 7,
    img: logoJaninSports,
    link: "https://centrecommercesbourg.fr/commerce/janin-sports/",
  },
  {
    id: 8,
    img: logoBernet,
    link: "#",
  },
  {
    id: 9,
    img: logoG2F,
    link: "https://www.g2f-automobiles.fr/",
  },
];

export default imgPartners;
