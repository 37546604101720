// ************************ images 2019
import img20191 from "../../../assets/img/img-pickleball/01-imgPickleball-2019-608x236.jpg";
import img20192 from "../../../assets/img/img-pickleball/02-imgPickleball-2019-604x288.jpg";
import img20193 from "../../../assets/img/img-pickleball/03-imgPickleball-2019-604x140.jpg";
import img20194 from "../../../assets/img/img-pickleball/04-imgPickleball-2019-262x306.jpg";
import img20195 from "../../../assets/img/img-pickleball/05-imgPickleball-2019-240x304.jpg";
import img20196 from "../../../assets/img/img-pickleball/06-imgPickleball-2019-1200x1600.jpg";
import img20197 from "../../../assets/img/img-pickleball/07-imgPickleball-2019-1824x1368.jpg";
import img20198 from "../../../assets/img/img-pickleball/08-imgPickleball-2019-1824x1368.jpg";
import img20199 from "../../../assets/img/img-pickleball/09-imgPickleball-2019-1824x1368.jpg";
import img201910 from "../../../assets/img/img-pickleball/10-imgPickleball-2019-1591x851.jpg";
// ************************ images 2020
import img20201 from "../../../assets/img/img-pickleball/01-imgPickleball-2020-606x228.jpg";
import img20202 from "../../../assets/img/img-pickleball/02-imgPickleball-2020-604x312.jpg";
import img20203 from "../../../assets/img/img-pickleball/03-imgPickleball-2020-290x248.jpg";
import img20204 from "../../../assets/img/img-pickleball/04-imgPickleball-2020-260x248.jpg";
import img20205 from "../../../assets/img/img-pickleball/05-imgPickleball-2020-960x720.jpg";
import img20206 from "../../../assets/img/img-pickleball/06-imgPickleball-2020-960x720.jpg";
import img20207 from "../../../assets/img/img-pickleball/07-imgPickleball-2020-960x720.jpg";
import img20208 from "../../../assets/img/img-pickleball/08-imgPickleball-2020-960x720.jpg";
import img20209 from "../../../assets/img/img-pickleball/09-imgPickleball-2020-960x720.jpg";
import img202010 from "../../../assets/img/img-pickleball/10-imgPickleball-2020-960x720.jpg";
import img202011 from "../../../assets/img/img-pickleball/11-imgPickleball-2020-960x720.jpg";
import img202012 from "../../../assets/img/img-pickleball/12-imgPickleball-2020-960x720.jpg";
import img202013 from "../../../assets/img/img-pickleball/13-imgPickleball-2020-960x720.jpg";
// ************************ images 2022
import img20221 from "../../../assets/img/img-pickleball/01-imgPickleball-2022-566x234.jpg";
import img20222 from "../../../assets/img/img-pickleball/02-imgPickleball-2022-560x204.jpg";
import img20223 from "../../../assets/img/img-pickleball/03-imgPickleball-2022-348x266.jpg";
// ************************ images 2023
import img20231 from "../../../assets/img/img-pickleball/01-imgPickleball-2023-472x288.jpg";
import img20232 from "../../../assets/img/img-pickleball/02-imgPickleball-2023-220x278.jpg";
import img20233 from "../../../assets/img/img-pickleball/03-imgPickleball-2023-212x268.jpg";
// ************************ images 2024
import img20241 from "../../../assets/img/img-pickleball/01-imgPickleball-2024-1156x500.jpg";
import img20242 from "../../../assets/img/img-pickleball/02-imgPickleball-2024-953x600.jpg";
import img20243 from "../../../assets/img/img-pickleball/03-imgPickleball-2024-967x600.jpg";
import img20244 from "../../../assets/img/img-pickleball/04-imgPickleball-2024-812x600.jpg";
import img20245 from "../../../assets/img/img-pickleball/05-imgPickleball-2024-650x325.jpg";
import img20246 from "../../../assets/img/img-pickleball/06-imgPickleball-2024-740x325.jpg";
import img20247 from "../../../assets/img/img-pickleball/07-imgPickleball-2024-795x365.jpg";
import img20248 from "../../../assets/img/img-pickleball/08-imgPickleball-2024-900x455.jpg";
import img20249 from "../../../assets/img/img-pickleball/09-imgPickleball-2024-800x600.jpg";
// ************************ images 2025
import img202501 from "../../../assets/img/img-pickleball/01-imgPickleball-2025-425x600.jpg";
import img202502 from "../../../assets/img/img-pickleball/01-imgPickleball-2025-800x645.png";
import img202503 from "../../../assets/img/img-pickleball/10-imgPickleball-2025-1000x393.png";
import img202504 from "../../../assets/img/img-pickleball/01-imgPickleball-2025-800x498.png";
import img202505 from "../../../assets/img/img-pickleball/02-imgPickleball-2025-900x425.png";
import img202506 from "../../../assets/img/img-pickleball/03-imgPickleball-2025-800x506.png";
import img202507 from "../../../assets/img/img-pickleball/04-imgPickleball-2025-800x517.png";
import img202508 from "../../../assets/img/img-pickleball/05-imgPickleball-2025-900x440.png";
import img202509 from "../../../assets/img/img-pickleball/06-imgPickleball-2025-800x474.png";
import img202510 from "../../../assets/img/img-pickleball/08-imgPickleball-2025-900x404.png";
import img202511 from "../../../assets/img/img-pickleball/09-imgPickleball-2025-600x619.png";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabPickleball = [
  {
    id: 1,
    season: "2019",
    sessions: [
      {
        date: "09/02/2019",
        num: 17,
        comment:
          "🏆 Vainqueurs : Lonny JARILLO et Maël CHAPUIS - Finalistes : Yvan BESSON / Romain GOYON",
        images: [
          { img: img20191, width: "608", height: "236" },
          { img: img20192, width: "604", height: "288" },
          { img: img20193, width: "604", height: "140" },
          { img: img20194, width: "262", height: "306" },
          { img: img20195, width: "240", height: "304" },
          { img: img20196, width: "1200", height: "1600" },
          { img: img20197, width: "1824", height: "1368" },
          { img: img20198, width: "1824", height: "1368" },
          { img: img20199, width: "1824", height: "1368" },
          { img: img201910, width: "1591", height: "851" },
        ],
      },
    ],
  },
  {
    id: 2,
    season: "2020",
    sessions: [
      {
        date: "23/11/2019",
        num: 24,
        comment:
          "🏆 Vainqueurs : Cédric et Evan JARILLO - Finalistes : Jérôme et Arthur PANIE",
        images: [
          { img: img20201, width: "606", height: "228" },
          { img: img20202, width: "604", height: "312" },
          { img: img20203, width: "200", height: "248" },
          { img: img20204, width: "260", height: "248" },
          { img: img20205, width: "960", height: "720" },
          { img: img20206, width: "960", height: "720" },
          { img: img20207, width: "960", height: "720" },
          { img: img20208, width: "960", height: "720" },
          { img: img20209, width: "960", height: "720" },
          { img: img202010, width: "960", height: "720" },
          { img: img202011, width: "960", height: "720" },
          { img: img202012, width: "960", height: "720" },
          { img: img202013, width: "960", height: "720" },
        ],
      },
    ],
  },
  {
    id: 3,
    season: "2021",
    sessions: [
      {
        date: "06/02/2021",
        num: 0,
        comment: "Annulée pour cause de COVID",
        images: [{}],
      },
      {
        date: "21/11/2020",
        num: 0,
        comment: "Annulée pour cause de COVID",
        images: [{}],
      },
    ],
  },
  {
    id: 4,
    season: "2022",
    sessions: [
      {
        date: "20/11/2021",
        num: 15,
        comment:
          "🏆 Vainqueurs : Arnaud et Lenny GOLLIN - Finalistes : Stéphane DEVOUCOUX / Cindy AMORIM",
        images: [
          { img: img20221, width: "566", height: "234" },
          { img: img20222, width: "560", height: "204" },
          { img: img20223, width: "348", height: "266" },
        ],
      },
    ],
  },
  {
    id: 5,
    season: "2023",
    sessions: [
      {
        date: "22/11/2022",
        num: 23,
        comment:
          "🏆 Vainqueurs : Cédric et Lonny JARILLO - Finalistes : Lenny GOLLIN / Kenan DEMIRAY",
        images: [
          { img: img20231, width: "472", height: "288" },
          { img: img20232, width: "220", height: "278" },
          { img: img20233, width: "212", height: "268" },
        ],
      },
    ],
  },
  {
    id: 6,
    season: "2024",
    sessions: [
      {
        date: "11/11/2023",
        num: 26,
        comment:
          "🏆 Vainqueurs : Stéphane DEVOUCOUX / Antoine SCHULLER - Finalistes : Laurent BLONDEAU / XX",
        images: [
          { img: img20241, width: "1156", height: "500" },
          { img: img20242, width: "953", height: "600" },
          { img: img20243, width: "967", height: "600" },
          { img: img20244, width: "812", height: "600" },
          { img: img20245, width: "650", height: "325" },
          { img: img20246, width: "740", height: "325" },
          { img: img20247, width: "795", height: "365" },
          { img: img20248, width: "900", height: "455" },
          { img: img20249, width: "800", height: "600" },
        ],
      },
    ],
  },
  {
    id: 7,
    season: "2025",
    sessions: [
      {
        date: "23/11/2024",
        num: 42,
        comment:
          "🏆 Vainqueurs : Charles BERTRAND / Amaël CANIOU - Finalistes : Stéphane DEVOUCOUX / Julien VANNIER",
        images: [
          { img: img202501, width: "425", height: "600" },
          { img: img202502, width: "800", height: "645" },
          { img: img202503, width: "1000", height: "393" },
          { img: img202504, width: "800", height: "498" },
          { img: img202505, width: "900", height: "425" },
          { img: img202506, width: "800", height: "506" },
          { img: img202507, width: "800", height: "517" },
          { img: img202508, width: "900", height: "440" },
          { img: img202509, width: "800", height: "474" },
          { img: img202510, width: "900", height: "404" },
          { img: img202511, width: "600", height: "619" },
        ],
      },
    ],
  },
];

const photosPickleball = tabPickleball.map((photo) => ({
  id: photo.id,
  season: photo.season,
  sessions: photo.sessions.map((session) => {
    return {
      date: session.date,
      num: session.num,
      comment: session.comment,
      images: session.images.map((image) => {
        return {
          src: image.img,
          width: image.width,
          height: image.height,
          srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round(
              (image.height / image.width) * breakpoint
            );
            return {
              src: image.img,
              width: breakpoint,
              height: height,
            };
          }),
        };
      }),
    };
  }),
}));

export default photosPickleball;
