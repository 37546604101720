import imgClubHouse0 from "../../../assets/img/img-installations/clubHouse-1526x1062.jpg";
import imgClubHouse1 from "../../../assets/img/img-installations/clubHouse-4000x1800.jpg";
import imgClubHouse2 from "../../../assets/img/img-installations/clubHouse-626x574.jpg";
import imgClubHouse3 from "../../../assets/img/img-installations/clubHouse-700x540.png";
import imgClubVuDuCiel from "../../../assets/img/img-installations/clubVuDuCiel-664x884.jpg";
import imgCourtsCouverts from "../../../assets/img/img-installations/courtsCouvert-657x494.jpg";
import imgCourtsCouverts1 from "../../../assets/img/img-installations/courtsCouvertsTCF-908x338.png";
import imgCourtsExterieurs01 from "../../../assets/img/img-installations/courtsExterieurs-2039x816.jpeg";
import imgCourtsExterieurs02 from "../../../assets/img/img-installations/courtsExterieurs-1000x353.png";
import imgCourtsExterieurs03 from "../../../assets/img/img-installations/courtsExterieurs-908x608.png";
import imgCourtsExterieurs04 from "../../../assets/img/img-installations/courtsExterieurs-1400x414.png";
import imgCourtsExterieurs05 from "../../../assets/img/img-installations/courtsExterieurs-3024x2017.jpg";
import imgCourtsExterieurs06 from "../../../assets/img/img-installations/courtsExterieurs-1200x385.png";
import imgCourtsExterieurs07 from "../../../assets/img/img-installations/courtsExterieurs-1000x341.png";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabInstallations = [
  {
    id: "clubHouse",
    title: "Notre clubhouse",
    images: [
      { img: imgClubHouse0, width: "1526", height: "1062" },
      { img: imgClubHouse1, width: "4000", height: "1800" },
      { img: imgClubHouse2, width: "626", height: "574" },
      { img: imgClubHouse3, width: "700", height: "540" },
      { img: imgClubVuDuCiel, width: "664", height: "884" },
    ],
  },
  {
    id: "courtsCouverts",
    title: "Nos courts couverts",
    images: [
      { img: imgCourtsCouverts, width: "664", height: "574" },
      { img: imgCourtsCouverts1, width: "908", height: "338" },
    ],
  },
  {
    id: "courtsExterieurs",
    title: "Nos courts extérieurs",
    images: [
      { img: imgCourtsExterieurs01, width: "2039", height: "816" },
      { img: imgCourtsExterieurs02, width: "1000", height: "353" },
      { img: imgCourtsExterieurs03, width: "908", height: "608" },
      { img: imgCourtsExterieurs04, width: "1400", height: "414" },
      { img: imgCourtsExterieurs05, width: "3024", height: "2017" },
      { img: imgCourtsExterieurs06, width: "1200", height: "385" },
      { img: imgCourtsExterieurs07, width: "1000", height: "341" },
    ],
  },
];

const photosInstallations = tabInstallations.map((photo) => ({
  id: photo.id,
  title: photo.title,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosInstallations;
