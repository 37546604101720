import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/sections/s-header";
import NavbarSection from "../components/sections/s-navbar";
import Footer from "../components/sections/s-footer";
import { NavHeightProvider } from "../_utils/context/navheightprovider";
import BtnTopPage from "../_utils/tools/btn-toppage";
import { Container } from "react-bootstrap";

const AdminLayout = () => {
  return (
    <Container fluid className="gx-0">
      <Header />
      <NavHeightProvider>
        <NavbarSection />
        <Outlet />
        <BtnTopPage />
      </NavHeightProvider>
      <Footer />
    </Container>
  );
};

export default AdminLayout;
