import imgnews0 from "../../../assets/img/img-animations/02_venteFromageVin.png";
import imgnews1 from "../../../assets/img/img-animations/14-doubledenoel-914x320.png";
import imgnews2 from "../../../assets/img/img-news/inscriptions-450x300.png";
import imgnews3 from "../../../assets/img/img-news/retardataire-450x300.png";
import imgnews4 from "../../../assets/img/img-pickleball/imgPickleball-jeu-450x300.png";
// import imgnewsX from "../../../assets/img/img-pickleball/imgPickleball-affiche-202411-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/newBadge-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/repriseCours-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/TCF-OctobreRose2024-1068x632.jpeg";
// import imgnewsX from "../../../assets/img/img-news/ag-2023-tcf-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/inscriptionTournoi-450x300.png";
const tableNews = [
  {
    id: 0,
    img: imgnews0,
    title: "Vente de fromage",
    texte:
      "Pour les fêtes de Noël et fin d'année, le club propose un plateau de fromage du jura et de vins du beaujolais venu tout droit des producteurs. Passez votre commande avant le 6/12 ...",
    link: "/animations/ventes",
  },
  {
    id: 1,
    img: imgnews1,
    title: "Tournoi de doubles de Noël",
    texte:
      "Cette année le tournoi se déroulera du 23/12 au 30/12. Date limite d'inscription le 15/12. Inscrivez-vous auprès de Fréd.",
    link: "/contact",
  },
  {
    id: 2,
    img: imgnews2,
    title: "Adhésion au club 2025",
    texte:
      "Pour les enfants intéressés, il n'est pas trop tard. Contactez-nous ...",
    link: "/contact",
  },
  {
    id: 3,
    img: imgnews3,
    title: "Reprise des cours 2025",
    texte:
      "Pour les adultes interessés, il n'est pas trop tard. Contactez-nous ...",
    link: "/contact",
  },
  {
    id: 4,
    img: imgnews4,
    title: "PICKLEBALL 2025",
    texte:
      "NOUVELLE ACTIVITÉ : Une section Pickleball s'ouvre au TC Feillens à partir de septembre 2024. RENSEIGNEZ-VOUS ...",
    link: "/enseignement#pickleball",
  },
  // {
  //   id: 0,
  //   img: imgnewsX,
  //   title: "Tournoi amical de Pickleball",
  //   texte:
  //     "Compétition amicale, ouverte à tous, à pratiquer en famille ou entre amis. INSCRIVEZ-VOUS ! 🙋‍♂️🙋🏻‍♀️ 📞 ➜ 06 59 52 03 88 ...",
  //   link: "/animations/pickleball",
  // },
  // {
  //   id: 1,
  //   img: imgnewsX,
  //   title: "Carte d'accès courts extérieurs 2024",
  //   texte:
  //     "La carte a changé. Pour obtenir la nouvelle clé, il suffit de rapporter l'ancienne aux membres du bureau ...",
  //   link: "/contact",
  // },
  // {
  //   id: 0,
  //   img: imgnewsX,
  //   title: "Après-midi Octobre ROSE",
  //   texte:
  //     "Journée au profit de l'association - Les Fées Papillons. Venez nombreux à partir de 14h ...",
  //   link: "https://centrecommercesbourg.fr/commerce/janin-sports/",
  // },
  // {
  //   id: 4,
  //   img: imgnewsX,
  //   title: "Licences d'été 2024",
  //   texte:
  //     "Les adhésions pour bénéficier des courts de tennis durant l'été (juin, juillet, août) sont ouvertes => Tarif Adulte : 55 €. CONTACTEZ-NOUS !",
  //   link: "https://centrecommercesbourg.fr/commerce/janin-sports/",
  // },
  // {
  //   id: 5,
  //   img: imgnewsX,
  //   title: "Assemblée Générale 2023",
  //   texte: "Samedi 21/10/2023 à 18h au clubhouse.",
  //   link: "https://centrecommercesbourg.fr/commerce/janin-sports/",
  // },
];

export default tableNews;
