import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import TopPageArrow from "../../assets/icons/icons8-thick-arrow-pointing-up-64.png";

const BtnTopPage = () => {
  /******* Positionnement en haut de page ****************************************/
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <OverlayTrigger
      placement={"right"}
      overlay={<Tooltip className="mc-rfs-7">Haut de page</Tooltip>}
    >
      <Button
        className="position-fixed bottom-0 start-0 m-3 p-0 border border-4 border-danger"
        style={{ zIndex: 1000 }}
        onClick={handleScrollToTop}
      >
        <Image
          className="rounded-0 bg-primary-subtle"
          style={{ height: "35px", width: "35px" }}
          src={TopPageArrow}
          thumbnail
        />
      </Button>
    </OverlayTrigger>
  );
};

export default BtnTopPage;
