import trenteAns from "./30_ans.jpg";
import quaranteAns from "./40_ans.jpg";
import jumelageBadWaldsee from "./jumelageBW.jpg";
import rg2013 from "./TCF_sur_le_central_400x400.png";

const imgEvents = [
  {
    id: 1,
    img: trenteAns,
    title: "Fête des 30 ans",
    subTitle: "Le club a fêté ses 30 ans ...",
    path: "/events/30ans",
  },
  {
    id: 2,
    img: quaranteAns,
    title: "Fête des 40 ans",
    subTitle: "Le club a fêté ses 40 ans ...",
    path: "/events/40ans",
  },
  {
    id: 3,
    img: jumelageBadWaldsee,
    title: "Jumelage",
    subTitle: "Rencontres avec nos amis du TC Bad Waldsee",
    path: "/events/jumelage",
  },
  {
    id: 4,
    img: rg2013,
    title: " Le TCF à Roland GARROS",
    subTitle: 'Le TCF vainqueur du concours "We Are Tennis"',
    path: "/events/rg2013",
  },
];

export default imgEvents;
