/* 2018 **************************************************************/
/* Jeunes */
import imgTournoi2018JFinale from "../../../assets/img/img-tournois/imgTournoi-2018-JFinale-600x413_v0.png";
import imgTournoi2018JSGV from "../../../assets/img/img-tournois/imgTournoi-2018-JSGV-700x475.png";
import imgTournoi2018JSF1112 from "../../../assets/img/img-tournois/imgTournoi-2018-JSF1112-700x790.png";
import imgTournoi2018JSF1316 from "../../../assets/img/img-tournois/imgTournoi-2018-JSF1316-700x700.png";
import imgTournoi2018JSG1112 from "../../../assets/img/img-tournois/imgTournoi-2018-JSG1112-700x711.png";
import imgTournoi2018JSG1314 from "../../../assets/img/img-tournois/imgTournoi-2018-JSG1314-700x656.png";
import imgTournoi2018JSG1516 from "../../../assets/img/img-tournois/imgTournoi-2018-JSG1516-700x719.png";
/* Seniors */
import imgTournoi2018SFinale from "../../../assets/img/img-tournois/imgTournoi-2018-SFinale-700x492_v0.png";
import imgTournoi2018SSD from "../../../assets/img/img-tournois/imgTournoi-2018-SSD-700x717.png";
import imgTournoi2018SSM from "../../../assets/img/img-tournois/imgTournoi-2018-SSM-700x643.png";
import imgTournoi2018SSM35 from "../../../assets/img/img-tournois/imgTournoi-2018-SSM35-700x679.png";
/* 2019 **************************************************************/
/* Jeunes */
import imgTournoi2019JFinale from "../../../assets/img/img-tournois/imgTournoi-2019-JFinale-1000x553_v0.png";
import imgTournoi2019JSGO from "../../../assets/img/img-tournois/imgTournoi-2019-JSGO-700x553.png";
import imgTournoi2019JSF1314 from "../../../assets/img/img-tournois/imgTournoi-2019-JSF1314-700x582.png";
import imgTournoi2019JSG1112 from "../../../assets/img/img-tournois/imgTournoi-2019-JSG1112-600x838.png";
import imgTournoi2019JSG1314 from "../../../assets/img/img-tournois/imgTournoi-2019-JSG1314-600x682.png";
import imgTournoi2019JSG1516 from "../../../assets/img/img-tournois/imgTournoi-2019-JSG1516-600x675.png";
/* Seniors */
import imgTournoi2019SFinale from "../../../assets/img/img-tournois/imgTournoi-2019-SFinale-615x531_v0.png";
import imgTournoi2019SSD from "../../../assets/img/img-tournois/imgTournoi-2019-SSD-700x520.png";
import imgTournoi2019SSM from "../../../assets/img/img-tournois/imgTournoi-2019-SSM-700x847.png";
import imgTournoi2019SSM35 from "../../../assets/img/img-tournois/imgTournoi-2019-SSM35-700x520.png";
/* 2020 **************************************************************/
/* Jeunes */
import imgTournoi2020JFinale from "../../../assets/img/img-tournois/imgTournoi-2020-JFinale-1000x607_v0.png";
import imgTournoi2020JSMXO from "../../../assets/img/img-tournois/imgTournoi-2020-JSMXO-700x400.png";
import imgTournoi2020JSMXV from "../../../assets/img/img-tournois/imgTournoi-2020-JSMXV-700x358.png";
import imgTournoi2020JSG1112 from "../../../assets/img/img-tournois/imgTournoi-2020-JSG1112-600x769.png";
import imgTournoi2020JSG1314 from "../../../assets/img/img-tournois/imgTournoi-2020-JSG1314-600x769.png";
import imgTournoi2020JSG1516 from "../../../assets/img/img-tournois/imgTournoi-2020-JSG1516-600x727.png";
/* 2021 **************************************************************/
/* Jeunes */
import imgTournoi2021JFinale from "../../../assets/img/img-tournois/imgTournoi-2021-JFinale-700x343.png";
import imgTournoi2021JSMXV from "../../../assets/img/img-tournois/imgTournoi-2021-JSMXV-700x331.png";
import imgTournoi2021JSG1112 from "../../../assets/img/img-tournois/imgTournoi-2021-JSG1112-700x724.png";
import imgTournoi2021JSG1316 from "../../../assets/img/img-tournois/imgTournoi-2021-JSG1316-700x590.png";
/* 2022 **************************************************************/
/* Jeunes */
import imgTournoi2022JFinale from "../../../assets/img/img-tournois/imgTournoi-2022-JFinale-1000x487_v0.png";
import imgTournoi2022JSMXV from "../../../assets/img/img-tournois/imgTournoi-2022-JSMXV-900x422.png";
import imgTournoi2022JSG1314 from "../../../assets/img/img-tournois/imgTournoi-2022-JSG1314-600x699.png";
import imgTournoi2022JSG1516 from "../../../assets/img/img-tournois/imgTournoi-2022-JSG1516-600x689.png";
/* Seniors */
import imgTournoi2022SFinale from "../../../assets/img/img-tournois/imgTournoi-2022-SFinale-856x465.png";
import imgTournoi2022SSD from "../../../assets/img/img-tournois/imgTournoi-2022-SSD-700x764.png";
import imgTournoi2022SSM from "../../../assets/img/img-tournois/imgTournoi-2022-SSM-700x824.png";
import imgTournoi2022SSM35 from "../../../assets/img/img-tournois/imgTournoi-2022-SSM35-700x602.png";
/* 2023 **************************************************************/
/* Jeunes */
import imgTournoi2023JFinale from "../../../assets/img/img-tournois/imgTournoi-2023-JFinale-900x496_v0.png";
import imgTournoi2023JSGO from "../../../assets/img/img-tournois/imgTournoi-2023-JSGO-700x411.png";
import imgTournoi2023JSGV from "../../../assets/img/img-tournois/imgTournoi-2023-JSGV-700x400.png";
import imgTournoi2023JSG1112 from "../../../assets/img/img-tournois/imgTournoi-2023-JSG1112-700x616.png";
import imgTournoi2023JSG1314 from "../../../assets/img/img-tournois/imgTournoi-2023-JSG1314-700x550.png";
import imgTournoi2023JSG1516 from "../../../assets/img/img-tournois/imgTournoi-2023-JSG1516-700x495.png";
/* Seniors */
import imgTournoi2023SFinale from "../../../assets/img/img-tournois/imgTournoi-2023-SFinale-985x595.png";
import imgTournoi2023SSD from "../../../assets/img/img-tournois/imgTournoi-2023-SSD-700x497.png";
import imgTournoi2023SSM from "../../../assets/img/img-tournois/imgTournoi-2023-SSM-700x502.png";
import imgTournoi2023SSM35 from "../../../assets/img/img-tournois/imgTournoi-2023-SSM35-700x878.png";
/* 2024 **************************************************************/
/* Jeunes */
import imgTournoi2024JFinale from "../../../assets/img/img-tournois/imgTournoi-2024-JFinale-1000x583_v0.png";
import imgTournoi2024JSGO from "../../../assets/img/img-tournois/imgTournoi-2024-JSGO-700x607.png";
import imgTournoi2024JSMXV from "../../../assets/img/img-tournois/imgTournoi-2024-JSMXV-700x423.png";
import imgTournoi2024JSG1112 from "../../../assets/img/img-tournois/imgTournoi-2024-JSG1112-700x785.png";
import imgTournoi2024JSG1314 from "../../../assets/img/img-tournois/imgTournoi-2024-JSG1314-700x724.png";
import imgTournoi2024JSG1516 from "../../../assets/img/img-tournois/imgTournoi-2024-JSG1516-700x769.png";
/* Seniors */
import imgTournoi2024 from "../../../assets/img/img-tournois/imgTournoi-2024-SFinale-700x595.png";
import imgTournoi2024SSD from "../../../assets/img/img-tournois/imgTournoi-2024-SSD-700x700.png";
import imgTournoi2024SSM from "../../../assets/img/img-tournois/imgTournoi-2024-SSM-700x771.png";
import imgTournoi2024SSM35 from "../../../assets/img/img-tournois/imgTournoi-2024-SSM35-700x791.png";
/* Afiches du tournoi *************************************************/
import posterTournoi2018 from "../../../assets/img/img-tournois/posterTournoi-2018-318x492.png";
import posterTournoi2019 from "../../../assets/img/img-tournois/posterTournoi-2019-375x531.png";
import posterTournoi2022 from "../../../assets/img/img-tournois/posterTournoi-2022-329x465.png";
import posterTournoi2023 from "../../../assets/img/img-tournois/posterTournoi-2023-421x595.png";
import posterTournoi2024 from "../../../assets/img/img-tournois/posterTournoi-2024-422x595.png";
import posterTournoiCovid from "../../../assets/img/img-tournois/posterTournoi-covid-1280x960.jpg";
/* Image inconnue ******************************************************/
import imgTournoiUnknown from "../../../assets/img/img-tournois/imgTournoi-unkonwn-960x960.jpg";

const tablePlayersTournois = [
  {
    id: 0,
    category: "Seniors",
    seasons: [
      {
        seasonId: 0,
        season: "2018",
        date: "08/06/2018 au 24/06/2018",
        registered: 104,
        tournamentNum: 35,
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018SFinale,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors NC à 4/6",
            registered: 88,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 17,
                finalWinnerName: "BON Katia (4/6)",
                finalWinnerClub: "TC PRISSE",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "CARRON Valentine (5/6)",
                finalLoserClub: "TC NORD REVERMONT",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "AH-HONE Maéva (15/1)",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "DUPONT Julie (15/1)",
                semiFinalLoser2Club: "TC HURIGNY",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 71,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "GUINET Valentin (15/2)",
                finalLoserClub: "TC PARAY LE MONIAL",
                finalLoserSemiFinaleScore: "3/6 - 6/4 - 6/4",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "JARILLO Evan (5/6)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 16,
                finalWinnerName: "ALLIOT Jérôme (15/4)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "GAUVRIT Frédéric (15/2)",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "GONOD Stéphane (15/4)",
                semiFinalLoser1Club: "TC MANZIAT",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoi2018SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 1,
        season: "2019",
        date: "07/06/2019 au 23/06/2019",
        registered: 77,
        tournamentNum: 36,
        poster: posterTournoi2019,
        photoFinale: imgTournoi2019SFinale,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors NC à 4/6",
            registered: 61,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 17,
                finalWinnerName: "BORNAREL Tatiana (4/6)",
                finalWinnerClub: "TC MACON",
                finalScore: "6/3 - 4/6 - 7/6(6)",
                finalWinnerSemiFinalScore: "6/3 - 6/1",
                finalLoserName: "MOTTE Virginia (4/6)",
                finalLoserClub: "TC BEAUNE",
                finalLoserSemiFinaleScore: "6/4 - 5/7 - 6/4",
                semiFinalLoser1Name: "OCHOA MARTINEZ Angelica (4/6)",
                semiFinalLoser1Club: "AC LEMPDES COL",
                semiFinalLoser2Name: "AH-HONE Maéva (15)",
                semiFinalLoser2Club: "TC HURIGNY",
                photoFinalEvent: imgTournoi2019SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 44,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "6/0 - 6/9",
                finalWinnerSemiFinalScore: "6/2 - 6/0",
                finalLoserName: "JARILLO Evan (5/6)",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "6/2 - 4/6 - 2/1 Ab.",
                semiFinalLoser1Name: "JARILLO Lonny (15)",
                semiFinalLoser1Club: "6/2 - 6/0",
                semiFinalLoser2Name: "AKO Maxime (15/1)",
                semiFinalLoser2Club: "TC MACON",
                photoFinalEvent: imgTournoi2019SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 16,
                finalWinnerName: "LEFEBVRE Tony (15/4)",
                finalWinnerClub: "TC MANZIAT",
                finalScore: "6/4 - 6/3",
                finalWinnerSemiFinalScore: "6/3 - 6/1",
                finalLoserName: "ALLIOT Jérôme (15/3)",
                finalLoserClub: "TC ATTIGNAT",
                finalLoserSemiFinaleScore: "6/3 - 6/3",
                semiFinalLoser1Name: "BURG Laurent (15/5)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "GORDONNAT Eric (15/5)",
                semiFinalLoser2Club: "TC MANZIAT",
                photoFinalEvent: imgTournoi2019SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 2,
        season: "2020",
        date: "13/06/2020 au 28/06/2020",
        registered: 0,
        tournamentNum: "Aucune",
        poster: posterTournoiCovid,
        photoFinale: imgTournoiUnknown,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors NC à 4/6",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
        ],
      },
      {
        seasonId: 3,
        season: "2021",
        date: "12/06/2021 au 27/06/2021",
        registered: 0,
        tournamentNum: "Aucune",
        poster: posterTournoiCovid,
        photoFinale: imgTournoiUnknown,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors NC à 4/6",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
        ],
      },
      {
        seasonId: 4,
        season: "2022",
        date: "10/06/2022 au 26/06/2022",
        registered: 87,
        tournamentNum: 37,
        poster: posterTournoi2022,
        photoFinale: imgTournoi2022SFinale,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors",
            registered: 71,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 14,
                finalWinnerName: "BON Katia - 5/6",
                finalWinnerClub: "TC PRISSE",
                finalScore: "6/1 - 6/1",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "CASTELLETTO Joanna - 15/1",
                finalLoserClub: "TC PRISSE",
                finalLoserSemiFinaleScore: "w.o.",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "DUPONT Julie - 15/1",
                semiFinalLoser2Club: "TC HURIGNY",
                photoFinalEvent: imgTournoi2022SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 57,
                finalWinnerName: "BLOT Florian (4/6)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/0 - 1/0 Ab.)",
                finalWinnerSemiFinalScore: "3/6 - 6/3 - 7/5",
                finalLoserName: "BEYNEL Guillaume (4/6)",
                finalLoserClub: "PLANETE TENNIS",
                finalLoserSemiFinaleScore: "6/3 - 7/5",
                semiFinalLoser1Name: "SAUDRAIS Sohel (5/6)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "JARILLO Lonny (15/2)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2022SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 16,
                finalWinnerName: "SIRAUD Robert (15/5)",
                finalWinnerClub: "TC3 BAGE DOMMARTIN",
                finalScore: "6/4 - 3/1 Ab.",
                finalWinnerSemiFinalScore: "6/1 - 7/5",
                finalLoserName: "LEFEBVRE Tony (15/4)",
                finalLoserClub: "TC3 MANZIAT",
                finalLoserSemiFinaleScore: "w.o.",
                semiFinalLoser1Name: "GORDONNAT Eric (15/5)",
                semiFinalLoser1Club: "TC MANZIAT",
                semiFinalLoser2Name: "GROSJEAN Cédric (15/2)",
                semiFinalLoser2Club: "TC VAL DE SAONE CHAL.",
                photoFinalEvent: imgTournoi2022SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 5,
        season: "2023",
        date: "08/06/2023 au 25/06/2023",
        registered: 98,
        tournamentNum: 38,
        poster: posterTournoi2023,
        photoFinale: imgTournoi2023SFinale,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors NC à 4/6",
            registered: 85,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 19,
                finalWinnerName: "GILLET Sabrina (4/6)",
                finalWinnerClub: "TC HURIGNY",
                finalScore: "7/6(7) - 4/6 - 6/3",
                finalWinnerSemiFinalScore: "6/1 - 6/3",
                finalLoserName: "DUPONT Julie (4/6)",
                finalLoserClub: "TC HURIGNY",
                finalLoserSemiFinaleScore: "6/4 - 2/6 - 6/1",
                semiFinalLoser1Name: "OCHOA MARTINEZ Angelica (5/6)",
                semiFinalLoser1Club: "TC SAVIGNOIS",
                semiFinalLoser2Name: "CASTELLETTO Joanna (5/6)",
                semiFinalLoser2Club: "TC PRISSE",
                photoFinalEvent: imgTournoi2023SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 66,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "6/3 - 6/2",
                finalWinnerSemiFinalScore: "6/2-7/6(9)",
                finalLoserName: "BALESTE Damien (4/6)",
                finalLoserClub: "TC MACON",
                finalLoserSemiFinaleScore: "6/0 - 6/0",
                semiFinalLoser1Name: "BLOT Florian (5/6)",
                semiFinalLoser1Club: "TC BOURG EN BRESSE",
                semiFinalLoser2Name: "MADRID TORRES Miguel AAngel (4/6)",
                semiFinalLoser2Club: "CLUB ETRANGER FEDERATION",
                photoFinalEvent: imgTournoi2023SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 13,
                finalWinnerName: "FANON Yann (15/2)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/2 - 6/4",
                finalWinnerSemiFinalScore: "6/4 - 6/3",
                finalLoserName: "HOSSEPIED Ronan (15/3)",
                finalLoserClub: "TC3 BAGE DOMMARTIN",
                finalLoserSemiFinaleScore: "7/5 - 4/6 - 6/2",
                semiFinalLoser1Name: "GUILLEMAUD David (15/5)",
                semiFinalLoser1Club: "TC VEYLE SAONE",
                semiFinalLoser2Name: "LEFBVRE Tony (15/5)",
                semiFinalLoser2Club: "TC MANZIAT",
                photoFinalEvent: imgTournoi2023SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 6,
        season: "2024",
        date: "06/06/2024 au 23/06/2024",
        registered: 105,
        tournamentNum: 39,
        poster: posterTournoi2024,
        photoFinale: imgTournoi2024,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors NC à 4/6",
            registered: 82,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 14,
                finalWinnerName: "ODET Louise-Line - 15",
                finalWinnerClub: "TC MACON",
                finalScore: "6/7(5) - 6/0 - 7/5",
                finalWinnerSemiFinalScore: "6/0 - 6/3",
                finalLoserName: "GILLET Sabrina - 4/6",
                finalLoserClub: "HURIGNY TC",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "PACCAUD Lucie - 15/3",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoi2024SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 68,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "6/3 - 0/6 - 6/4",
                finalWinnerSemiFinalScore: "6/1-7/6(5)",
                finalLoserName: "LECLERCQ Kellian (4/6)",
                finalLoserClub: "TC MONTMERLE sur Saône",
                finalLoserSemiFinaleScore: "3/6 - 6/4 - 6/4",
                semiFinalLoser1Name: "DESSERTENNE Quentin (15/1)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "MOUROT Robin (5/6)",
                semiFinalLoser2Club: "ALC LONGVIC",
                photoFinalEvent: imgTournoi2024SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 23,
                finalWinnerName: "FANON Yann (15/2)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/2 - 6/1",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "HOSSEPIED Ronan (15/3)",
                finalLoserClub: "TC3 BAGE DOMMARTIN",
                finalLoserSemiFinaleScore: "6/3 - 6/1",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "GUILLEMAUD David (15/5)",
                semiFinalLoser2Club: "TC VEYLE SAONE",
                photoFinalEvent: imgTournoi2024SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 7,
        season: "2025",
        date: "07/06/2025 au 23/06/2025",
        registered: 0,
        tournamentNum: 40,
        poster: imgTournoiUnknown,
        photoFinale: imgTournoiUnknown,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Seniors NC à 4/6",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 1,
    category: "Jeunes",
    seasons: [
      {
        seasonId: 0,
        season: "2018",
        date: "25/08/2018 au 02/09/2018",
        registered: 47,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018JFinale,
        competitions: [
          {
            competitionId: 0,
            competitionLabel: "Défi VERT - 9/10 ans - Niv 3",
            registered: 8,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 8,
                finalWinnerName: "DUBAND-GEORGELIN Raphaël (N3)",
                finalWinnerClub: "TC CHARNAY-LES-MACON",
                finalScore: "4/0 - 4/2",
                finalWinnerSemiFinalScore: "4/1 - 4/1",
                finalLoserName: "CORNUT-CHAUVINC Jules (N3)",
                finalLoserClub: "TC AGGLO VILLEFRANCHE",
                finalLoserSemiFinaleScore: "4/1 - 4/0",
                semiFinalLoser1Name: "VEZINET Tristan (N3)",
                semiFinalLoser1Club: "MEYZIEU TENNIS",
                semiFinalLoser2Name: "GOLLIN Lennys (N2)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2018JSGV,
              },
            ],
          },
          {
            competitionId: 1,
            competitionLabel: "11/12 ans - NC à 15/4",
            registered: 16,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 4,
                finalWinnerName: "BOUILLOUX Kim (30)",
                finalWinnerClub: "TC SUD REVERMONT",
                finalScore: "6/2 - 7/5",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "GOMES Maïa (30/1)",
                finalLoserClub: "TC LA PAPE",
                finalLoserSemiFinaleScore: "6/2 - 6/4",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "ALBAN Eléna (30/1)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2018JSF1112,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 12,
                finalWinnerName: "BONNET-EYMARD Victor (15/4)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/0 - 6/1",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "ALLEGRE Louis (30)",
                finalLoserClub: "TC CHALON",
                finalLoserSemiFinaleScore: "6/4 - 7/5",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "LECLERCQ Zack (30/1)",
                semiFinalLoser2Club: "TC BELLEVILLE",
                photoFinalEvent: imgTournoi2018JSG1112,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "13/14 ans - NC à 15/1",
            registered: 10,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 10,
                finalWinnerName: "PANIE Arthur (15/1)",
                finalWinnerClub: "TC FEILLENS",
                finalScore: "6/2 - 6/0",
                finalWinnerSemiFinalScore: "6/0 - 6/1",
                finalLoserName: "JARILLO Lonny (15/1)",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "6/1 - 6/0",
                semiFinalLoser1Name: "BONNET-EYMARD Victor (15/4)",
                semiFinalLoser1Club: "TC BOURG-EN-BRESSE",
                semiFinalLoser2Name: "BARAKET Yasser (15/2)",
                semiFinalLoser2Club: "TC MONTLUEL",
                photoFinalEvent: imgTournoi2018JSG1314,
              },
            ],
          },
          {
            competitionId: 3,
            competitionLabel: "15/16 ans - NC à 4/6",
            registered: 13,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 6,
                finalWinnerName: "THEVENEAU Elsa (15/2)",
                finalWinnerClub: "TC MEZERIAT",
                finalScore: "6/3 - 6/2",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "MARGUERON Raphaëlle (15)",
                finalLoserClub: "TC CULOZ",
                finalLoserSemiFinaleScore: "6/1 - 6/à",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "BOUILLOUX Kim (30)",
                semiFinalLoser2Club: "TC SUD REVERMONT",
                photoFinalEvent: imgTournoi2018JSF1316,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 7,
                finalWinnerName: "DEBOUT Baptiste (15/2)",
                finalWinnerClub: "TC TREVOUX",
                finalScore: "3/2 ab.",
                finalWinnerSemiFinalScore: "6/0 - 6/2",
                finalLoserName: "AKO Maxime (15/1)",
                finalLoserClub: "TC MACON",
                finalLoserSemiFinaleScore: "6/0 - 6/1",
                semiFinalLoser1Name: "ZAEGEL Mattéo (15/1)",
                semiFinalLoser1Club: "TC CHARBONNIERES",
                semiFinalLoser2Name: "JARILLO Lonny (15/1)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2018JSG1516,
              },
            ],
          },
        ],
      },
      {
        seasonId: 1,
        season: "2019",
        date: "24/08/2019 au 31/08/2019",
        registered: 53,
        tournamentNum: "-",
        poster: posterTournoi2019,
        photoFinale: imgTournoi2019JFinale,
        competitions: [
          {
            competitionId: 0,
            competitionLabel: "Défi ORANGE - 9/10 ans - NC à 30/1",
            registered: 4,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple MIXTE",
                registered: 4,
                finalWinnerName: "LO RICCO Elise",
                finalWinnerClub: "TENNIS CHASSIEU",
                finalScore: "3/2 - 3/1",
                finalWinnerSemiFinalScore: "3/1 - 3/1",
                finalLoserName: "ALBAN Quentin",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "3/0 - 3/0",
                semiFinalLoser1Name: "SALGADO Rafael",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "PARNALAND Maxence",
                semiFinalLoser2Club: "TC SIMANDRE",
                photoFinalEvent: imgTournoi2019JSGO,
              },
            ],
          },
          {
            competitionId: 1,
            competitionLabel: "Défi VERT - 9/10 ans - NC à 30/4",
            registered: 8,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple MIXTE",
                registered: 8,
                finalWinnerName: "ONECHANH SOIL Théo",
                finalWinnerClub: "TENNIS CHASSIEU",
                finalScore: "4/2 - 4/0",
                finalWinnerSemiFinalScore: "4/3 - 4/2",
                finalLoserName: "REVELUT Valentin",
                finalLoserClub: "TC BLACEEN",
                finalLoserSemiFinaleScore: "4/1 - 4/2",
                semiFinalLoser1Name: "GAZELLE Benjamin",
                semiFinalLoser1Club: "TC AGGLO VILLEFRANCHE",
                semiFinalLoser2Name: "CROS Arthur",
                semiFinalLoser2Club: "TC NORD BEAUJOLAIS",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "11/12 ans - NC à 15/4",
            registered: 10,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 10,
                finalWinnerName: "BOSIO Augustin (15/4)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/3 - 6/0",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "POUPET-CHEVALIER Ethan (30/2)",
                finalLoserClub: "ENT VAL SAONE CHALARONNE",
                finalLoserSemiFinaleScore: "6/0 - 6/0",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "MAITRE Benjamin (30/2)",
                semiFinalLoser2Club: "TC NORD REVERMONT",
                photoFinalEvent: imgTournoi2019JSG1112,
              },
            ],
          },
          {
            competitionId: 3,
            competitionLabel: "13/14 ans - NC à 15/1",
            registered: 19,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 7,
                finalWinnerName: "FRAY Noémie (15/1)",
                finalWinnerClub: "TC MACON",
                finalScore: "6/0 - 6/4",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "GOMES Maïa (30)",
                finalLoserClub: "TC LA PAPE",
                finalLoserSemiFinaleScore: "6/3 - 6/0",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "ALBAN Eléna (30/1)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2019JSF1314,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 12,
                finalWinnerName: "DEFALLETANS Benoit (15/3)",
                finalWinnerClub: "TC FEILLENS",
                finalScore: "6/0 - 6/1",
                finalWinnerSemiFinalScore: "6/2 - 6/3",
                finalLoserName: "CLEMENT Allan (15/3)",
                finalLoserClub: "TC FLEYRIAT",
                finalLoserSemiFinaleScore: "0/6 - 6/1 - 6/0",
                semiFinalLoser1Name: "LOISY Enzo (30)",
                semiFinalLoser1Club: "TC CAILLOUX s/FONTAINES",
                semiFinalLoser2Name: "GOUGE SIBELLE Aaron (15/4)",
                semiFinalLoser2Club: "TC LYON",
                photoFinalEvent: imgTournoi2019JSG1314,
              },
            ],
          },
          {
            competitionId: 4,
            competitionLabel: "15/16 ans - NC à 15",
            registered: 11,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 11,
                finalWinnerName: "PANIE Arthur (15)",
                finalWinnerClub: "TC FEILLENS",
                finalScore: "7/6(4) - 6/1",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "BULIDON Timothée (15/4)",
                finalLoserClub: "TC MACON",
                finalLoserSemiFinaleScore: "7/5 - 7/5",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "URCUN Corentin (15/3)",
                semiFinalLoser2Club: "TC MACON",
                photoFinalEvent: imgTournoi2019JSG1516,
              },
            ],
          },
        ],
      },
      {
        seasonId: 2,
        season: "2020",
        date: "22/08/2020 au 30/08/2020",
        registered: 37,
        tournamentNum: "-",
        poster: "",
        photoFinale: imgTournoi2020JFinale,
        competitions: [
          {
            competitionId: 0,
            competitionLabel: "Défi ORANGE - 9/10 ans",
            registered: 8,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Mixte",
                registered: 8,
                finalWinnerName: "LEBATTO Nils",
                finalWinnerClub: "TC REIGNIER",
                finalScore: "4/0 - 4/1",
                finalWinnerSemiFinalScore: "4/0 - 4/2",
                finalLoserName: "VORILLON Maé",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "3/4 - 4/2 - 10/8",
                semiFinalLoser1Name: "VORILLON Sacha",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "PENIN Louis",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2020JSMXO,
              },
            ],
          },
          {
            competitionId: 1,
            competitionLabel: "Défi VERT - 9/10 ans - NC à 30/3",
            registered: 8,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Mixte",
                registered: 8,
                finalWinnerName: "RAKOTOMAHANINA Nelson (30/4)",
                finalWinnerClub: "TC MEXIMIEUX",
                finalScore: "4/1 - 4/1",
                finalWinnerSemiFinalScore: "4/0 - 4/1",
                finalLoserName: "DUPLESSIX Abel (30/4)",
                finalLoserClub: "TC MEXIMIEUX",
                finalLoserSemiFinaleScore: "4/2 - 4/0",
                semiFinalLoser1Name: "PANIE César (30/5)",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "LO RICCO Elise (30/3)",
                semiFinalLoser2Club: "TC CHASSIEU",
                photoFinalEvent: imgTournoi2020JSMXV,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "11/12 ans - NC à 15/1",
            registered: 6,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 6,
                finalWinnerName: "DUBAND-GEORGELIN Raphaël (15/4)",
                finalWinnerClub: "TC CHALON",
                finalScore: "5/7 - 6/4 - 6/4",
                finalWinnerSemiFinalScore: "6/2 - 6/2",
                finalLoserName: "BILLOUD Axel (15/5)",
                finalLoserClub: "TC CHALON",
                finalLoserSemiFinaleScore: "6/1 - 7/6(5)",
                semiFinalLoser1Name: "PELISSARD Léo (30/3)",
                semiFinalLoser1Club: "PLANETE TENNIS",
                semiFinalLoser2Name: "DEMIRAY Kenan (30/4)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2020JSG1112,
              },
            ],
          },
          {
            competitionId: 3,
            competitionLabel: "13/14 ans - NC à 15/1",
            registered: 8,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 8,
                finalWinnerName: "DUBAND-GEORGELIN Raphaël (15/4)",
                finalWinnerClub: "TC CHALON",
                finalScore: "6/2 - 6/4",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "BILLOUD Axel (15/5)",
                finalLoserClub: "TC CHALON",
                finalLoserSemiFinaleScore: "6/1 - 6/1",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "PELISSARD Léo (30/3)",
                semiFinalLoser2Club: "PLANETE TENNIS",
                photoFinalEvent: imgTournoi2020JSG1314,
              },
            ],
          },
          {
            competitionId: 4,
            competitionLabel: "15/16 ans - NC à 15/1",
            registered: 7,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 7,
                finalWinnerName: "PANIE Arthur (15/1)",
                finalWinnerClub: "TC FEILLENS",
                finalScore: "6/2 - 6/1",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "LECERF Louis (15/2)",
                finalLoserClub: "TC PERONNAS",
                finalLoserSemiFinaleScore: "6/2 - 6/2",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "MASSIP Noé (15/4)",
                semiFinalLoser2Club: "TC AGGLO VILLEFRANCHE",
                photoFinalEvent: imgTournoi2020JSG1516,
              },
            ],
          },
        ],
      },
      {
        seasonId: 3,
        season: "2021",
        date: "21/08/2021 au 29/08/2021",
        registered: 37,
        tournamentNum: "-",
        poster: "",
        photoFinale: imgTournoi2021JFinale,
        competitions: [
          {
            competitionId: 0,
            competitionLabel: "Défi ORANGE - 8/10 ans",
            registered: 4,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Mixte",
                registered: 4,
                finalWinnerName: "GAIFFAS Léon",
                finalWinnerClub: "PARIS UC",
                finalScore: "4/0 - 4/0",
                finalWinnerSemiFinalScore: "w.o.",
                finalLoserName: "PEREZ LOPEZ Ana",
                finalLoserClub: "TC SANCE",
                finalLoserSemiFinaleScore: "4/1 - 4/0",
                semiFinalLoser1Name: "ZAGHDOUD Adam",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "BARRET Elsa",
                semiFinalLoser2Club: "TC MONTMERLE s/ SAONE",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
          {
            competitionId: 1,
            competitionLabel: "Défi VERT - 8/10 ans - NC à 30/3",
            registered: 16,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Mixte",
                registered: 16,
                finalWinnerName: "DA SILVA Loris (30/3)",
                finalWinnerClub: "TC CLAIRVALIEN",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "PELLE Luc (30/4)",
                finalLoserClub: "TC CHARVIEU",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "STEVENS Hugo (30/5)",
                semiFinalLoser1Club: "TC BOURG-EN-BRESSE",
                semiFinalLoser2Name: "BERGEROB Louis (30/3)",
                semiFinalLoser2Club: "ENT VAL SAONE CHALARONNE",
                photoFinalEvent: imgTournoi2021JSMXV,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "11/12 ans - NC à 30",
            registered: 4,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 4,
                finalWinnerName: "TESORIELLI-MASNADA Marius (30/3)",
                finalWinnerClub: "TC PERONNAS",
                finalScore: "6/2 - 6/0",
                finalWinnerSemiFinalScore: "6/1 - 6/2",
                finalLoserName: "DUPONT Quentin (30/3)",
                finalLoserClub: "TC MACON",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "COEUR Jules (30/5)",
                semiFinalLoser1Club: "AS LANTIGNE TENNIS",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoi2021JSG1112,
              },
            ],
          },
          {
            competitionId: 3,
            competitionLabel: "13/16 ans - NC à 15/4",
            registered: 13,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 13,
                finalWinnerName: "COPAESCU Toma (30)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "4/6 - 6/4 - 6/1",
                finalWinnerSemiFinalScore: "5/7 - 6/4 - 6/3",
                finalLoserName: "GAMBIN Raphaël (30/1)",
                finalLoserClub: "TC ST ANDRE DE CORCY",
                finalLoserSemiFinaleScore: "Repêché (perd 7/6(3) - 6/3)",
                semiFinalLoser1Name: "CORNUT-CHAUVINC Jules (15/4)",
                semiFinalLoser1Club: "TC TREVOUX",
                semiFinalLoser2Name: "SICCARDI Maxime (15/4)",
                semiFinalLoser2Club: "CHALAMONTENNIS",
                photoFinalEvent: imgTournoi2021JSG1316,
              },
            ],
          },
        ],
      },
      {
        seasonId: 4,
        season: "2022",
        date: "20/08/2022 au 27/08/2022",
        registered: 45,
        tournamentNum: "-",
        poster: posterTournoi2022,
        photoFinale: imgTournoi2022JFinale,
        competitions: [
          {
            competitionId: 1,
            competitionLabel: "Défi VERT - 8/10 ans - NC à 30/1",
            registered: 16,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Mixte",
                registered: 16,
                finalWinnerName: "LAMOUR-FORNIER Léo (30/1)",
                finalWinnerClub: "TC CLAIX",
                finalScore: "4/1 - 4/0",
                finalWinnerSemiFinalScore: "4/0 - 4/0",
                finalLoserName: "CIRON Eden (30/2)",
                finalLoserClub: "NEVERS ASPTT",
                finalLoserSemiFinaleScore: "1/4 - 4/3(3) - 11/9",
                semiFinalLoser1Name: "MORARD Agathe (30/2)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "GODUADZE Andria (30/2)",
                semiFinalLoser2Club: "TC MOULIN A VENT",
                photoFinalEvent: imgTournoi2022JSMXV,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "11/12 ans - NC à 30/1",
            registered: 5,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 5,
                finalWinnerName: "GONDCAILLE Basile (40)",
                finalWinnerClub: "TC LYON I",
                finalScore: "7/6(2) - 6/1",
                finalWinnerSemiFinalScore: "6/4 - 6/2",
                finalLoserName: "SALGADO Rafael (30/4)",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "6/3 - 6/4",
                semiFinalLoser1Name: "BOURGEOIS Quentin (30/5)",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "LAY Aslan (30/5)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "13/14 ans - NC à 15/1",
            registered: 11,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 11,
                finalWinnerName: "DUBAND-GEROGELIN Raphaël (15/1)",
                finalWinnerClub: "TC MACON",
                finalScore: "7/5 - 4/6 - 6/0",
                finalWinnerSemiFinalScore: "6/1 - 6/1",
                finalLoserName: "ZIDIGA Adam (15/3)",
                finalLoserClub: "ASUL TENNIS",
                finalLoserSemiFinaleScore: "6/2 - 6/0",
                semiFinalLoser1Name: "HEGO Alec (15/5)",
                semiFinalLoser1Club: "TC BOURG-EN-BRESSE",
                semiFinalLoser2Name: "CROS Arthur (15/2)",
                semiFinalLoser2Club: "TC NORD BEAUJOLAIS",
                photoFinalEvent: imgTournoi2022JSG1314,
              },
            ],
          },
          {
            competitionId: 3,
            competitionLabel: "15/16 ans - NC à 15/1",
            registered: 13,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 13,
                finalWinnerName: "VISIER Corentin (15/2)",
                finalWinnerClub: "TC LYON",
                finalScore: "3/6 - 6/1 - 6/1",
                finalWinnerSemiFinalScore: "6/2 - 6/0",
                finalLoserName: "DUBAND-GEROGELIN Raphaël (15/1)",
                finalLoserClub: "TC MACON",
                finalLoserSemiFinaleScore: "6/4 - 6/1",
                semiFinalLoser1Name: "CORNUT-CHAUVIN Jules (15/1)",
                semiFinalLoser1Club: "TC TREVOUX",
                semiFinalLoser2Name: "ZIDIGA ADAM (15/3)",
                semiFinalLoser2Club: "ASUL TENNIS",
                photoFinalEvent: imgTournoi2022JSG1516,
              },
            ],
          },
        ],
      },
      {
        seasonId: 5,
        season: "2023",
        date: "19/08/2023 au 27/08/2023",
        registered: 47,
        tournamentNum: "-",
        poster: posterTournoi2023,
        photoFinale: imgTournoi2023JFinale,
        competitions: [
          {
            competitionId: 0,
            competitionLabel: "Défi ORANGE - 8/10 ans",
            registered: 8,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Mixte",
                registered: 8,
                finalWinnerName: "BASSET Stanislas",
                finalWinnerClub: "TC FAREINS",
                finalScore: "4/3(3) - 4/0",
                finalWinnerSemiFinalScore: "4/0 - 4/1",
                finalLoserName: "COEUR Hyppolyte",
                finalLoserClub: "AS LANTIGNIE TENNIS",
                finalLoserSemiFinaleScore: "4/1 - 3/4(5) - 10/3",
                semiFinalLoser1Name: "GIUGE Raphaël",
                semiFinalLoser1Club: "TC AIX AUC",
                semiFinalLoser2Name: "LELOUTRE Hugo",
                semiFinalLoser2Club: "TC ST REMY",
                photoFinalEvent: imgTournoi2023JSGO,
              },
            ],
          },
          {
            competitionId: 1,
            competitionLabel: "Défi VERT - 8/10 ans - NC à 30/3",
            registered: 10,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 10,
                finalWinnerName: "SOUMER Amjad (30/3)",
                finalWinnerClub: "TC MARCY L'ETOILE",
                finalScore: "4/1 - 4/0",
                finalWinnerSemiFinalScore: "4/1 - 4/0",
                finalLoserName: "CAMERLO Isaac (NC)",
                finalLoserClub: "AS POUILLY LES NONAINS",
                finalLoserSemiFinaleScore: "4/3(10) - 4/1",
                semiFinalLoser1Name: "PIOTTE Ludovic (30/5)",
                semiFinalLoser1Club: "TC BUELLAS ST DENIS",
                semiFinalLoser2Name: "DUSSARRAT (30/5)",
                semiFinalLoser2Club: "ASPTT BOURG ST REMY",
                photoFinalEvent: imgTournoi2023JSGV,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "11/12 ans - NC à 30/1",
            registered: 8,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 8,
                finalWinnerName: "STEVENS Hugo (30/1)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/2 - 2/6 - 7/5",
                finalWinnerSemiFinalScore: "6/2 - 6/2",
                finalLoserName: "SECK Youssouf (30/1)",
                finalLoserClub: "ANNEMASSE TC DU SALEVE",
                finalLoserSemiFinaleScore: "6/2 - 6/1",
                semiFinalLoser1Name: "ORMANCEY Jules (30/2)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "REBOUT Hugo (30/2)",
                semiFinalLoser2Club: "TC FAREINS",
                photoFinalEvent: imgTournoi2023JSG1112,
              },
            ],
          },
          {
            competitionId: 3,
            competitionLabel: "13/14 ans - NC à 30/1",
            registered: 11,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 2,
                finalWinnerName: "BADOUX Hermance (30/5)",
                finalWinnerClub: "TC PONT DE VAUX",
                finalScore: "6/4 - 6/3",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "AUPOIL Lise (30/5)",
                finalLoserClub: "TC VEYLE SAONE",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 9,
                finalWinnerName: "COEUR Jules (30)",
                finalWinnerClub: "AS LANTIGNIE TENNIS",
                finalScore: "w.o.",
                finalWinnerSemiFinalScore: "4/6 - 6/1 - 6/0",
                finalLoserName: "CHARPENTIER Gabin (15/4)",
                finalLoserClub: "TC AIX LES BAINS",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "ACHKOYAN Luka (30)",
                semiFinalLoser1Club: "TC LA CHAPELLE DE GUINCHAY",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoi2023JSG1314,
              },
            ],
          },
          {
            competitionId: 4,
            competitionLabel: "15/16 ans - NC à 15/1",
            registered: 10,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 10,
                finalWinnerName: "PELISSARD Léo (15/4)",
                finalWinnerClub: "PLANETE TENNIS",
                finalScore: "6/2 - 6/4",
                finalWinnerSemiFinalScore: "6/2 - 6/3",
                finalLoserName: "ACHKOYAN Elie (30/1)",
                finalLoserClub: "TC LA CHAPELLE DE GUINCHAY",
                finalLoserSemiFinaleScore: "w.o.",
                semiFinalLoser1Name: "COEUR Achyl (30/1)",
                semiFinalLoser1Club: "AS LANTIGNIE TENNIS",
                semiFinalLoser2Name: "CHARPENTIER Gabin (15/4)",
                semiFinalLoser2Club: "TC AIX LES BAINS",
                photoFinalEvent: imgTournoi2023JSG1516,
              },
            ],
          },
        ],
      },
      {
        seasonId: 6,
        season: "2024",
        date: "24/08/2024 au 01/09/2024",
        registered: 45,
        tournamentNum: "-",
        poster: posterTournoi2024,
        photoFinale: imgTournoi2024JFinale,
        competitions: [
          {
            competitionId: 0,
            competitionLabel: "Défi ORANGE - 8/10 ans",
            registered: 4,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 4,
                finalWinnerName: "LHERMENIER Othello",
                finalWinnerClub: "TC SCIEZ-MASSONGY",
                finalScore: "2/4 - 4/1 - 10/5",
                finalWinnerSemiFinalScore: "4/0 - 4/0",
                finalLoserName: "BARLATIER Eddy",
                finalLoserClub: "TC ST PRIEST",
                finalLoserSemiFinaleScore: "4/0 - 4/2",
                semiFinalLoser1Name: "GENESTOUX Edgar",
                semiFinalLoser1Club: "TC -AMBARROIS",
                semiFinalLoser2Name: "GATINET Valentin",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2024JSGO,
              },
            ],
          },
          {
            competitionId: 1,
            competitionLabel: "Défi VERT - 8/10 ans - NC à 30/1",
            registered: 14,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Mixte",
                registered: 14,
                finalWinnerName: "NONNOTTE Nathan (30/2)",
                finalWinnerClub: "TC MEXIMIEUX",
                finalScore: "4/1 - 4/3(5)",
                finalWinnerSemiFinalScore: "4/0 - 3/4(9) - 10/4",
                finalLoserName: "BARTOLOMEU Augustin (30/3)",
                finalLoserClub: "TC BOURG-EN-BRESSE",
                finalLoserSemiFinaleScore: "4/2 - 4/0",
                semiFinalLoser1Name: "GUYARD Alexis (30/4)",
                semiFinalLoser1Club: "TC NORD REVERMONT",
                semiFinalLoser2Name: "FLORE Milo (30/4)",
                semiFinalLoser2Club: "TC MEXIMIEUX",
                photoFinalEvent: imgTournoi2024JSMXV,
              },
            ],
          },
          {
            competitionId: 2,
            competitionLabel: "11/12 ans - NC à 15/1",
            registered: 9,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 9,
                finalWinnerName: "KILIC Enis (30/2)",
                finalWinnerClub: "TC VIRIAT",
                finalScore: "6/3 - 6/3",
                finalWinnerSemiFinalScore: "6/2 - 6/1",
                finalLoserName: "NONNOTTE Nathan (30/2)",
                finalLoserClub: "TC MEXIMIEUX",
                finalLoserSemiFinaleScore: "6/3 - 6/2",
                semiFinalLoser1Name: "ORMANCEY Arthur (30/3)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "ROUSSEAU Nathan (30/4)",
                semiFinalLoser2Club: "TC ATTIGNAT",
                photoFinalEvent: imgTournoi2024JSG1112,
              },
            ],
          },
          {
            competitionId: 3,
            competitionLabel: "13/14 ans - NC à 15/1",
            registered: 11,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 11,
                finalWinnerName: "GONDCAILLE Basile (15/5)",
                finalWinnerClub: "TENNIS LYON I",
                finalScore: "4/6 - 6/0 - 6/1",
                finalWinnerSemiFinalScore: "6/4 - 6/1",
                finalLoserName: "GAUDET Ethan (15/5)",
                finalLoserClub: "TC AGGLO VILLEFRANCHE",
                finalLoserSemiFinaleScore: "6/3 - 6/3",
                semiFinalLoser1Name: "BARLATIER Raphaël (30)",
                semiFinalLoser1Club: "TC ST PRIEST",
                semiFinalLoser2Name: "SALVADE Maël (30/2)",
                semiFinalLoser2Club: "TC ATTIGNAT",
                photoFinalEvent: imgTournoi2024JSG1314,
              },
            ],
          },
          {
            competitionId: 4,
            competitionLabel: "15/16 ans - NC à 15/1",
            registered: 7,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Garçons",
                registered: 7,
                finalWinnerName: "GONDCAILLE Basile (15/5)",
                finalWinnerClub: "TENNIS LYON I",
                finalScore: "6/2 - 6/2",
                finalWinnerSemiFinalScore: "6/0 - 6/0",
                finalLoserName: "MEYNET Johan (30)",
                finalLoserClub: "TC LA CHAPELLE DE GUINCHAY",
                finalLoserSemiFinaleScore: "6/2 - 6/3",
                semiFinalLoser1Name: "LARGE Merlin (30/2)",
                semiFinalLoser1Club: "TP BOURG LES VALENCE",
                semiFinalLoser2Name: "COEUR Jules (15/5)",
                semiFinalLoser2Club: "TC MONTMERLE s/ SAONE",
                photoFinalEvent: imgTournoi2024JSG1516,
              },
            ],
          },
        ],
      },
      {
        seasonId: 7,
        season: "2025",
        date: "23/08/2025 au 31/08/2025",
        registered: 0,
        tournamentNum: "-",
        poster: imgTournoiUnknown,
        photoFinale: imgTournoiUnknown,
        competitions: [
          {
            competitionId: 0,
            competitionLabel: "-",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "-",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: "",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default tablePlayersTournois;
