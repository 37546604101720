import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SideMenu from "../../../components/menus/m-side";
import PhotoAlbum from "react-photo-album";
import photosInstallations from "../../../_params/tab-pages/leclub/tab-installations";
import { useNavHeight } from "../../../_utils/context/navheightprovider";

const NosInstallations = () => {
  const { navbarHeight } = useNavHeight();

  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="m-auto">
            <h1 className="my-3 mc-rampart mc-rfs-2 mc-title text-center">
              Nos installations
            </h1>
            {photosInstallations.map((img) => {
              return (
                <div key={img.id} className="mc-container-club">
                  <Row className="mc-container-histoire1">
                    <Card className="border border-5 rounded-4 p-2 mc-card text-light">
                      <Card.Body className="p-0">
                        <Card.Title className="display-7 fw-bold text-center mb-3">
                          {img.title}
                        </Card.Title>
                        <PhotoAlbum layout="rows" photos={img.images} />
                      </Card.Body>
                    </Card>
                  </Row>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NosInstallations;
