import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";
import { motion } from "framer-motion";
import imgEvents from "../../../assets/img/img-events/images-events";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.3,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const Events = () => {
  return (
    <div className="mc-fond-ecran">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          {imgEvents.map((event) => {
            return (
              <Col sm={6} md={4} key={event.id}>
                <Card className="p-2 m-2 text-light">
                  <Card.Img src={event.img} alt={"Image évènement ?"} />
                  <Card.ImgOverlay>
                    <Card.Title className="fw-bold">{event.title}</Card.Title>
                    <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                      {event.subTitle}
                    </Card.Text>
                    <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                      <NavLink
                        to={event.path}
                        className="px-3 p-1 border border-2 border-muted bg-warning rounded text-center nav-link"
                      >
                        <motion.div
                          className="text-dark fw-semibold"
                          variants={buttonVariants}
                          whileHover="hover"
                        >
                          <span className="pe-2">Voir</span>
                          <IconContext.Provider value={{ size: "1em" }}>
                            <FaChevronRight />
                          </IconContext.Provider>
                        </motion.div>
                      </NavLink>
                    </Nav>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Events;
